<template>
  <div>
    <div class="row">
      <div class="col-md-12 spam-mails-table">
        <!-- <KTCodePreview v-bind:title="'Spam'"> -->
        <br />
        <v-app style="min-height: auto" id="spam-form">
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                label="Introdu cuvant"
                single-line
                hide-details
              ></v-text-field>
              <v-btn @click="options.page !== 1 ? (options.page = 1) : getDataFromApi()">
                Cauta
              </v-btn>
            </v-card-title>

            <v-data-table
              :page="page"
              :pageCount="numberOfPages"
              :headers="headers"
              :items="spamMails"
              :options.sync="options"
              :server-items-length="totalMails"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 15, 30] }"
              item-key="name"
              class="elevation-1"
            >
              <template v-slot:item.customer.email="{ item }">
                <ConversationLink :item="item" :linktext="item.customer.email" />
              </template>
              <template v-slot:top>
                <!-- <v-switch
                              v-model="singleSelect"
                              label="Single select"
                              class="pa-3"
                            ></v-switch> -->
                <UnSpamDialog
                  v-model="spamDialog"
                  :conversation="editedItem"
                  @un-spam="getDataFromApi"
                />
              </template>
              <template v-slot:item.agent="{ item }">
                <span>
                  {{ getSpamUser(item) }}
                </span>
              </template>
              <template v-slot:item.createdDate="{ item }">
                <!-- <Date :date="item.createdDate" /> -->
                {{ formatDate(item.createdDate) }}
              </template>
              <template v-slot:item.updatedAt="{ item }">
                <!-- <Date :date="item.updatedAt" /> -->
                {{ formatDate(item.updatedAt, true) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small @click="undoItem(item)"> mdi-undo </v-icon>
              </template>
              <template v-slot:no-data>
                <div>Nu exista mail-uri</div>
              </template>
            </v-data-table>
          </v-card>
        </v-app>

        <!-- </KTCodePreview> -->
      </div>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_WORKFLOWS } from "@/core/services/store/modules/workflow";
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";
import UnSpamDialog from "@/view/content/buttons/UnSpamDialog";
import { mapActions, mapGetters } from "vuex";
import ConversationLink from "@/view/pages/conversation/ConversationLink";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      search: "",
      spamDialog: false,
      page: 1,
      totalMails: 0,
      numberOfPages: 0,
      spamMails: [],

      loading: true,
      options: {
        itemsPerPage: 10,
      },
      headers: [
        {
          text: "Email client",
          align: "start",
          sortable: false,
          value: "customer.email",
          class: "h6 grey lighten-2",
        },
        {
          text: "Subiect client",
          value: "subject",
          sortable: false,
          class: "h6 grey lighten-2",
        },
        {
          text: "Departament",
          value: "departament",
          sortable: false,
          class: "h6 grey lighten-2",
        },
        {
          text: "Agent",
          value: "agent",
          sortable: false,
          class: "h6 grey lighten-2",
        },
        {
          text: "Data",
          value: "createdDate",
          sortable: false,
          class: "h6 grey lighten-2",
        },
        {
          text: "Data ultimei modificare",
          value: "updatedAt",
          sortable: false,
          class: "h6 grey lighten-2",
          width: "10%",
        },
        {
          text: "Anuleaza",
          value: "actions",
          sortable: false,
          class: "h6 grey lighten-2",
        },
      ],
      editedItem: null,
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Spam Mails" }]);
    // this.getDataFromApi();
  },
  computed: {
    // ...mapActions("mails", ["getMailsInSpam"]),
    // ...mapGetters("mails", ["mailsInSpam"]),
    ...mapGetters("departments", ["departments"]),
    ...mapGetters(["user"]),
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      if (this.search === "") {
        ApiService.get(
          "conversations?status=spam&embed=threads,last_action&sortField=updatedAt&per_page=" +
            itemsPerPage +
            "&page=" +
            page
        ).then((response) => {
          this.spamMails = response._embedded.conversations;
          this.totalMails = response.page.totalElements;
          this.numberOfPages = response.page.totalPages;
          this.loading = false;
        });
      } else {
        ApiService.get(
          "search/conversations?search=" +
            this.search +
            "&status=4&embed=threads,last_action&sortField=updatedAt&perPage=" +
            itemsPerPage +
            "&page=" +
            page
        ).then((response) => {
          this.spamMails = response.conversations;
          this.totalMails = response.page.totalElements;
          this.numberOfPages = response.page.totalPages;
          this.loading = false;
        });
      }
    },
    formatDate(date_created) {
      const date = new Date(date_created);
      return `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )} ${date.getFullYear()}`;
    },
    undoItem(item) {
      this.editedItem = Object.assign({}, item);
      this.spamDialog = true;
    },
    getSpamUser(item) {
      if (item.assignee) {
        return item.assignee.firstName;
      } else if (
        item._embedded.threads &&
        item._embedded.threads.lineitems[0].createdBy
      ) {
        return item._embedded.threads.lineitems[0].createdBy.firstName;
      } else {
        return "Regula";
      }
    },
  },
  created() {
    // this.getMailsInSpam;
    if (this.$store.getters["departments/departments"].length === 0) {
      this.$store.dispatch("departments/getDepartments");
    }
  },
  components: {
    UnSpamDialog,
    ConversationLink,
  },
};
</script>

<style>
.spam-mails-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
